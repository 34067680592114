<template>
  <div class="role">
    <Search :handleInput="handleInput" :handleOpenDialog="handleOpenDialog" />
    <Table
      :loading="loading"
      :rowHeader="rowHeader"
      :data="data"
      :pageObj="pageObj"
      :pageShow="pageShow"
      :handleSizeChange="handleSizeChange"
      :handleCurrentChange="handleCurrentChange"
    />
    <el-dialog
      :title="`${
        dialogType === 1
          ? '新增角色'
          : dialogType === 2
          ? '编辑角色'
          : dialogType === 4
          ? '关联权限'
          : dialogType === 5
          ? '分配用户'
          : '删除角色'
      }`"
      :visible.sync="dialogVisible"
      :width="`${dialogType === 5 ? '40%' : '30%'}`"
      :before-close="handleClose"
    >
      <div v-if="dialogVisible">
        <AddRole
          v-if="dialogType === 1"
          :handleClose="handleClose"
          :getRoleInsert="getRoleInsert"
        />
        <EditUser
          v-else-if="dialogType === 2"
          :handleClose="handleClose"
          :getRoleUpdate="getRoleUpdate"
          :paramsObj="paramsObj"
        />
        <BindDept
          v-else-if="dialogType === 4"
          :treeData="treeData"
          :paramsObj="paramsObj"
          :handleClose="handleClose"
          :getRoleUpdateRP="getRoleUpdateRP"
        />
        <BindUser v-else-if="dialogType === 5" :paramsObj="paramsObj" />
        <DeleteAny
          v-else
          :handleClose="handleClose"
          :name="paramsObj.name"
          :typeName="typeName"
          :getDelFunc="getRoleDelete"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Role",
  components: {
    Table: () => import("@/components/Table"),
    Search: () => import("./components/Search"),
    AddRole: () => import("./components/AddRole"),
    EditUser: () => import("./components/EditUser"),
    DeleteAny: () => import("@/components/DeleteAny"),
    BindDept: () => import("./components/BindDept"),
    BindUser: () => import("./components/BindUser"),
  },
  data() {
    return {
      pageShow: true,
      loading: false,
      pageObj: {
        current: 1,
        total: 0,
      },
      page: 1,
      size: 10,
      rowHeader: [
        {
          label: "序号",
          width: "80",
          render: (h, params) => {
            return (
              <span>
                {params.index + (this.pageObj.current - 1) * this.pageObj.size}
              </span>
            );
          },
        },
        {
          prop: "name",
          label: "角色名",
        },
        {
          prop: "remark",
          label: "说明",
        },
        {
          prop: "btn",
          label: "操作",
          width: "300",
          render: (h, params) => {
            return (
              <div class="btn_box">
                <span
                  class="btn"
                  onClick={() => this.handleOpenDialog(4, params.row)}
                >
                  关联权限
                </span>
                <span
                  class="btn"
                  onClick={() => this.handleOpenDialog(5, params.row)}
                >
                  分配用户
                </span>
                <span
                  class="btn"
                  onClick={() => this.handleOpenDialog(2, params.row)}
                >
                  编辑
                </span>
                <span
                  class="btn"
                  onClick={() => this.handleOpenDialog(3, params.row)}
                >
                  删除
                </span>
              </div>
            );
          },
        },
      ],
      data: [],
      treeData: [],
      name: "",
      dialogType: 1,
      dialogVisible: false,
      paramsObj: {},
      sidebarData: [],
      typeName: "角色",
    };
  },
  created() {
    this.$emit("tabIndex", "2-3");
    this.getRolePage();
    this.getPermissionTree();
  },
  methods: {
    handleInput(value) {
      this.name = value;
      this.page = 1;
      this.size = 10;
      this.getRolePage();
    },
    async getRolePage() {
      this.loading = true;
      const { data } = await this.$api.getRolePage({
        page: this.page,
        size: this.size,
        name: this.name,
        deptId: JSON.parse(window.sessionStorage.getItem("userInfo")).deptId,
      });
      this.data = data.records;
      this.pageObj = data;
      this.loading = false;
    },
    // 查询权限
    async getPermissionTree() {
      const { data } = await this.$api.getPermissionTree();
      this.treeData = data;
    },
    // 新增角色
    async getRoleInsert(roleObj) {
      const res = await this.$api.getRoleInsert(roleObj);
      this.$message({
        message: res.message,
        type: res.status === 200 ? "success" : "warning",
        duration: 1500,
      });
      this.getRolePage();
      this.handleClose();
    },
    // 更新用户
    async getRoleUpdate(roleObj) {
      const res = await this.$api.getRoleUpdate(roleObj);
      this.$message({
        message: res.message,
        type: res.status === 200 ? "success" : "warning",
        duration: 1500,
      });
      this.getRolePage();
      this.handleClose();
    },
    // 删除用户
    async getRoleDelete() {
      let ids = JSON.stringify([this.paramsObj.id]);
      const res = await this.$api.getRoleDelete({ reRoleIds: ids });
      this.$message({
        message: res.message,
        type: res.status === 200 ? "success" : "warning",
        duration: 1500,
      });
      this.getRolePage();
      this.handleClose();
    },
    // 编辑角色关联权限
    async getRoleUpdateRP(roleObj) {
      const res = await this.$api.getRoleUpdateRP(roleObj);
      this.$message({
        message: res.message,
        type: res.status === 200 ? "success" : "warning",
        duration: 1500,
      });
      this.getRolePage();
      this.handleClose();
    },
    // 条数
    handleSizeChange(val) {
      this.size = val;
      this.getRolePage();
    },
    // 页数
    handleCurrentChange(val) {
      this.page = val;
      this.getRolePage();
    },
    handleOpenDialog(id, row) {
      this.paramsObj = row;
      this.dialogType = id;
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.role {
  display: inline-block;
  width: calc(100% - 40px);
  min-height: calc(100vh - 110px);
  margin: 20px;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  text-align: center;
}
</style>
