var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"role"},[_c('Search',{attrs:{"handleInput":_vm.handleInput,"handleOpenDialog":_vm.handleOpenDialog}}),_c('Table',{attrs:{"loading":_vm.loading,"rowHeader":_vm.rowHeader,"data":_vm.data,"pageObj":_vm.pageObj,"pageShow":_vm.pageShow,"handleSizeChange":_vm.handleSizeChange,"handleCurrentChange":_vm.handleCurrentChange}}),_c('el-dialog',{attrs:{"title":("" + (_vm.dialogType === 1
        ? '新增角色'
        : _vm.dialogType === 2
        ? '编辑角色'
        : _vm.dialogType === 4
        ? '关联权限'
        : _vm.dialogType === 5
        ? '分配用户'
        : '删除角色')),"visible":_vm.dialogVisible,"width":("" + (_vm.dialogType === 5 ? '40%' : '30%')),"before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[(_vm.dialogVisible)?_c('div',[(_vm.dialogType === 1)?_c('AddRole',{attrs:{"handleClose":_vm.handleClose,"getRoleInsert":_vm.getRoleInsert}}):(_vm.dialogType === 2)?_c('EditUser',{attrs:{"handleClose":_vm.handleClose,"getRoleUpdate":_vm.getRoleUpdate,"paramsObj":_vm.paramsObj}}):(_vm.dialogType === 4)?_c('BindDept',{attrs:{"treeData":_vm.treeData,"paramsObj":_vm.paramsObj,"handleClose":_vm.handleClose,"getRoleUpdateRP":_vm.getRoleUpdateRP}}):(_vm.dialogType === 5)?_c('BindUser',{attrs:{"paramsObj":_vm.paramsObj}}):_c('DeleteAny',{attrs:{"handleClose":_vm.handleClose,"name":_vm.paramsObj.name,"typeName":_vm.typeName,"getDelFunc":_vm.getRoleDelete}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }